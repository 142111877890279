import React from "react";

const Investors = () => {
  return (
    <section className="border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-6xl mx-auto pb-4 md:pb-4">
            <h2 className="h2 mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                Our investors
              </span>
            </h2>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:gap-16 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-5xl pt-8 md:pt-12">
            <div className="relative h-32">
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  width={0}
                  height={0}
                  className="mx-auto w-auto h-auto"
                  src="https://encord.cdn.prismic.io/encord/ZsSrx0aF0TcGJHoH_CRVlogoinorange..svg"
                  alt="Charles River Ventures"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="relative h-32">
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  width={0}
                  height={0}
                  className="mx-auto w-auto h-auto"
                  src="https://images.prismic.io/encord/ZsSsfEaF0TcGJHo7_wnrdco.png?auto=format,compress"
                  alt="WnrdCo"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="relative h-32">
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  width={0}
                  height={0}
                  className="mx-auto w-auto h-auto"
                  src={
                    "https://images.prismic.io/encord/e72a1f67-06e7-4dde-8745-17559d88b5f9_investors-yc.png?auto=compress,format"
                  }
                  alt="Y Combinator"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="relative h-32">
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  width={0}
                  height={0}
                  className="mx-auto w-auto h-auto"
                  src="https://encord.cdn.prismic.io/encord/ZsSq5EaF0TcGJHnt_cranelogo.svg"
                  alt="Crane Venture Partners"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="relative h-32">
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  width={0}
                  height={0}
                  className="mx-auto w-auto h-auto"
                  src={
                    "https://images.prismic.io/encord/1dd52a12-0024-4541-8d34-435046ff0878_investors-harpoon.png?auto=compress,format"
                  }
                  alt="Harpoon Ventures"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="relative h-32">
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  width={0}
                  height={0}
                  className="mx-auto w-auto h-auto"
                  src="https://encord.cdn.prismic.io/encord/ZsSq4kaF0TcGJHnr_Next47-0.svg"
                  alt="Next 47"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Investors;
