import React from "react";

const Stats = () => {
  return (
    <section className="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:gap-16 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-5xl pt-8 md:pt-0">
            {/* 1st item */}
            <div className="text-center">
              <div className="h3 mb-1">2020</div>
              <div className="text-gray-600">Founded</div>
            </div>

            {/* 2nd item */}
            <div className="text-center">
              <div className="h3 mb-1">30</div>
              <div className="text-gray-600">Nationalities represented</div>
            </div>

            {/* 3rd item */}
            <div className="text-center">
              <div className="h3 mb-1">2000+</div>
              <div className="text-gray-600">Predictive & generative models put in production</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
