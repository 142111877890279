import React from "react";

const Team = () => {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="h2 mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                  Our leadership
                </span>
              </h2>
            </div>

            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                <li key={"Ulrik Stig Hansen"}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <img
                      className="w-24 h-24 rounded-full lg:w-32 lg:h-32"
                      src={
                        "https://images.prismic.io/encord/c0ce081f-1105-4a17-b1fb-da51c064aad9_person-ulrik.png?auto=compress,format"
                      }
                      alt={"Ulrik Stig Hansen"}
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <h3>Ulrik Stig Hansen</h3>
                      <p className="text-purple-600">
                        Founder & President
                      </p>{" "}
                      <br />
                      <a
                        href={
                          "https://www.linkedin.com/in/ulrik-stig-hansen-2658273b/"
                        }
                        className="text-gray-400 hover:text-gray-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </li>

                <li key={"Eric Landau"}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <img
                      className="w-24 h-24 rounded-full lg:w-32 lg:h-32"
                      src={
                        "https://images.prismic.io/encord/ca9417e2-f8a2-4a6c-aabc-f9345a1e85bf_person-eric.png?auto=compress,format"
                      }
                      alt={"Eric Landau"}
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <h3>Eric Landau</h3>
                      <p className="text-purple-600">Founder & CEO</p> <br />
                      <a
                        href={
                          "https://www.linkedin.com/in/eric-landau-40992ab0/"
                        }
                        className="text-gray-400 hover:text-gray-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </li>

                <li key={"Anna Khan"}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <img
                      className="w-16 h-16 rounded-full lg:w-32 lg:h-32"
                      src={
                        "https://images.prismic.io/encord/17b043de-d7fb-4f5c-a5dc-5652415469b4_person-anna.png?auto=compress,format"
                      }
                      alt={"Anna Khan"}
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <h3>Anna Khan</h3>
                      <p className="text-purple-600">
                        General Partner at CRV, Board Member
                      </p>{" "}
                      <br />
                      <a
                        href={"https://www.linkedin.com/in/annahrk/"}
                        className="text-gray-400 hover:text-gray-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </li>

                <li key={"Luc Vincent"}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <img
                      className="w-16 h-16 rounded-full lg:w-32 lg:h-32"
                      src={
                        "https://images.prismic.io/encord/626c2505-daef-4d5a-be0f-f2f00b093581_person-luc.png?auto=compress,format"
                      }
                      alt={"Luc Vincent"}
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                    <div className="font-medium text-lg leading-6 space-y-1">
                      <h3>Luc Vincent</h3>
                      <p className="text-purple-600">
                        VP of AI at Meta, Executive Advisor
                      </p>{" "}
                      <br />
                      <a
                        href={"https://www.linkedin.com/in/lucvincent/"}
                        className="text-gray-400 hover:text-gray-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
