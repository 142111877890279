import React from "react";

const OurStory = () => {
  return (
    <section className="bg-gradient-to-b from-gray-100 to-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-6xl mx-auto pb-4 md:pb-4">
            <h2 className="h2 mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                Our story
              </span>
            </h2>
          </div>

          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <p className="mt-8 text-xl text-gray-500 leading-8">
                Encord was started by former quants, physicists and computer
                scientists. We experienced firsthand how the lack of purpose-built tooling
                and infrastructure was impeding the progress of
                building practical AI applications. AI felt to us
                like what the early days of computing or the internet must have
                felt like, where the potential of the technology was clear, but
                the tools and processes surrounding it left much to be desired.
              </p>

              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                From first starting the company, we believed there must be a
                better way to making AI development practical.
              </p>

              <p className="mt-8 text-xl text-gray-500 leading-8">
                Team Encord is composed of a team with a deep technical
                foundation and a breadth of operating experience. Our people
                include engineers, operatives, and creatives from some of the largest technology
                companies on the planet, PhDs, and world-class alumni from
                Tessian, Meta, DRW, Goldman Sachs, Twilio, Microsoft, J.P. Morgan,
                and more.
              </p>

              <p className="mt-8 text-xl text-gray-500 leading-8">
                We are backed by some of the foremost leaders in the space
                including CRV, Y Combinator Continuity, top industry executives, and other leading Bay Area
                investors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurStory;
