import React from "react";

import Layout from "../components/layout";
import AboutIntro from "../partials/AboutIntro";
import Stats from "../partials/Stats";
import Team from "../partials/Team";
import CtaAlternative from "../partials/CtaAlternative";
import Investors from "../partials/Investors";
import OurStory from "../partials/OurStory";
import LeftColours from "../utilities/LeftColours";
import SEO from "../components/seo";

const AboutUs = ({ location }) => {
  return (
    <Layout location={location}>
      <LeftColours />

      {/*  Page sections */}
      <AboutIntro />
      <Stats />
      <Investors />
      {/* <Team /> */}
      <OurStory />
      <CtaAlternative
        ctaText={"Join us to help build the future of AI applications\n"}
      />
    </Layout>
  );
};

export default AboutUs;

export const Head = () => (
  <SEO
    title="Learn more about Encord"
    description="Find out more about Encord, the active learning platform for computer vision."
  />
);
