import React from "react";

const AboutIntro = () => {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-10 md:pt-40 md:pb-12">
          {/* Section header */}
          <div className=" mx-auto text-center pb-12 md:pb-16">
            <div className="flex flex-col gap-3">
            <span className="uppercase font-manrope text-xs sm:text-base tracking-[1.2px] sm:tracking-[1.6px] font-medium text-purple-1500">About us</span>
            <h1 className="relative leading-10 sm:leading-12 tracking-tightest_7 sm:tracking-tightest_5 md:tracking-tighter_3 max-w-[648px] md:max-w-4xl mx-auto text-3.25xl font-semibold text-purple-1100 font-manrope sm:text-4.5xl mb-5">
              Our mission is to enable <br className=" hidden lg:flex"/> every company to harness the <br className="xs:hidden flex"/>{" "}
              <strong className="text-3.25xl text-purple-1500 font-semibold font-manrope sm:text-4.5xl">
                power of AI
              </strong>
            </h1>
            </div>
          
            <p className="relative text-base mx-auto lg:w-[972px] sm:text-lg sm:-tracking-[0.45px] text-gray-600">
              Our team is working tirelessly to make AI development practical
              and fast by building a complete data-focused AI tooling &
              infrastructure stack focused on model evaluation & observability,
              annotation & workforce management tooling, and data curation &
              management software, to get your models working in production -
              fast.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
